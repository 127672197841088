import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainHeading from '../Common/MainHeading';
import { API_SERVER } from '../../Util';

const Signup = () => {
    const Token = "der324k343%@923041!";
    const [countryCodes, setCountryCodes] = useState({});
    const [selectedOption, setSelectedOption] = useState('option1');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const [formDetails, setFormDetails] = useState({
        Token: Token,
        FullName: "",
        Email: "",
        ContactNo: "",
        Country: 'SKBKE', 
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        Zip_Postalcode: "",
    });

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedOption(selectedValue); // Update selected option
        setFormDetails({ ...formDetails, Country: countryCodes[selectedValue]?.code || '' });
    };

    const handlePhoneChange = (e) => {
        let value = e.target.value;
        // Remove characters that are not numbers or specific special characters
        value = value.replace(/[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
        setFormDetails({ ...formDetails, ContactNo: value });
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormDetails(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (formDetails.Email !== '' && !emailRegex.test(formDetails.Email)) {
            toast.error('Invalid email format');
            return;
        }

        try {
            setLoader(true);
            const handleSendApi = await fetch(`${API_SERVER}/api/ContactUs/SignUp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formDetails)
            });
            const res = await handleSendApi.json();
            if (res.status === "SUCCESS") {
                setLoader(false);
                navigate('/thankyou?signing');
            } else if (res.status === "ERROR") {
                setLoader(false);
                toast.error(res.message);
            }
        } catch (error) {
            toast.error('Some error occurred. Please try again later.');
            setLoader(false);
        }
    };



    useEffect(() => {

        const getCountryData = async () => {
            const handleGetCountryApi = await fetch(`${API_SERVER}/api/ContactUs/GetCountry`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Token: Token,
                    CallType: "GetCountry",
                })
            });
            const res = await handleGetCountryApi.json();
            if (res.status === "SUCCESS") {
                setCountryData(res.countryDetails)
            }
            else if (res.status === "ERROR") {
                toast.error(res.message)
            }
        }
        getCountryData()
    }, []);

    useEffect(() => {
        if (countryData.length > 0) {
            const newCountryCodes = {};
            countryData.forEach((country, index) => {
                const optionKey = `option${index + 1}`;
                newCountryCodes[optionKey] = {
                    label: country.countryName,
                    countryCode: country.countryCode,
                    code: country.countryAlias
                };
            });
            setCountryCodes(newCountryCodes);
        }
    }, [countryData]);

    const renderCountryOptions = () => {
        return Object.keys(countryCodes).map((optionKey) => (
            <option key={optionKey} value={optionKey}>
                {countryCodes[optionKey].label}
            </option>
        ));
    };

    return (
        <>
            <Helmet>
                <title>Signup | Kangaroo Express</title>
            </Helmet>
            <section className='signup'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={10} lg={6} className='position-relative'>
                            <div className="signup_kangaroo d-none d-lg-block">
                                <img src="./images/signupkangaroo.png" alt="Signup" />
                            </div>
                            <div className="signup_box br-15">
                                <MainHeading heading='Register Now' />
                                <p>All [*] fields are mandatory.</p>
                                <Row>
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Enter Full Name*"
                                            className="mb-3"
                                        >
                                            <Form.Control onChange={handleChange} name='FullName' type="text" placeholder="John" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Enter Your Email*"
                                            className="mb-3"
                                        >
                                            <Form.Control type="email" onChange={handleChange} name='Email' placeholder="name@example.com" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className={`mb-3 position-relative`}>
                                            <Form.Select
                                                open={isDropdownOpen}
                                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                onChange={handleSelectChange}
                                            >
                                                {renderCountryOptions()}
                                            </Form.Select>
                                            <label className="country_label">Country</label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <label className="cntry_label mb-3">{countryCodes[selectedOption]?.code}</label>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Enter phone number"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" onChange={handlePhoneChange} value={formDetails.ContactNo} name='ContactNo' placeholder="Enter phone number" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Address Line 1"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" onChange={handleChange} name='AddressLine1' placeholder="Address Line 1" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={12}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Address Line 2"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" onChange={handleChange} name='AddressLine2' placeholder="Address Line 2" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="City"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" onChange={handleChange} name='City' placeholder="City" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Zip/Postal code"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" onChange={handleChange} name='Zip_Postalcode' placeholder="Zip/Postal code" />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                {!loader ? (
                                    <Button onClick={handleSubmit} className='themebtn mt-3'>Signup
                                        <div className="icon-box">
                                            <i className='bx bx-send bx-flashing' style={{ fontSize: '18px' }}></i>
                                        </div>
                                    </Button>
                                ) : (
                                    <Button disabled style={{ cursor: 'not-allowed' }} className='themebtn mt-3 '>Sending mail...
                                        <div className="icon-box">
                                            <i className='bx bx-send bx-flashing' style={{ fontSize: '18px' }}></i>
                                        </div>
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Signup;

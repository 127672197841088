import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const CTA = ({bg=false}) => {
    return (
        <>
            <section className={bg ? 'light-bg' : ''}>
                <Container>
                    <div className="d-block d-md-flex justify-content-between align-items-center">
                        <div>
                            <h3 className='text-theme'>Ready to Shop & Ship?</h3>
                            <p>All you need is a membership to get your Kangaroo Express address.</p>
                        </div>
                        <Link to='/contactus' className='themebtn mt-4 mt-md-0 rounded'>Contact Now
                            <div className="icon-box">
                            <i className='bx bx-chevron-right bx-fade-left' ></i>
                            </div>
                        </Link>
                    </div>
                </Container>
            </section>
        </>
    )
}

import React from 'react'
import { JamboHeader } from '../Common/JamboHeader'
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap'
import MainHeading from '../Common/MainHeading'

const Pricing = () => {
    return (
        <>
            <JamboHeader pagename="Pricing" />

            <section>

                <Container>
                    <MainHeading heading="Pricing" center={true} />
                    <p className='text-center'>Find the perfect plan for your shipping needs</p>
                    <Col>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><div className="primary-bg py-4 rounded">SINGLE PACKAGE</div></th>
                                    <th><div className="secondary-bg py-4 text-dark rounded">PREMIUM</div></th>
                                    <th><div className="primary-bg py-4 rounded">+ MAIL</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>I Want to ship</td>
                                    <td>One Package from One Store</td>
                                    <td>Packages from Multiple Stores</td>
                                    <td>Multiple Packages & US Mail</td>
                                </tr>
                                <tr>
                                    <td>MyUS Shipping Discount</td>
                                    <td><i class='bx bx-check text-success'></i></td>
                                    <td><i class='bx bx-check'></i></td>
                                    <td><i class='bx bx-check text-success'></i></td>
                                </tr>
                                <tr>
                                    <td>Premium Shipping Discount</td>
                                    <td><i class='bx bx-check text-success'></i></td>
                                    <td><i class='bx bx-check'></i></td>
                                    <td><i class='bx bx-check text-success'></i></td>
                                </tr>
                                <tr>
                                    <td>Up to 80% Consolidation Savings</td>
                                    <td><i class='bx bx-x text-danger' ></i></td>
                                    <td><i class='bx bx-check'></i></td>
                                    <td><i class='bx bx-check text-success'></i></td>
                                </tr>
                                <tr>
                                    <td>Free Package Repacking</td>
                                    <td><i class='bx bx-x text-danger' ></i></td>
                                    <td><i class='bx bx-check'></i></td>
                                    <td><i class='bx bx-check text-success'></i></td>
                                </tr>
                                <tr>
                                    <td>Free Package Storage</td>
                                    <td><i class='bx bx-x text-danger' ></i></td>
                                    <td>30 Days</td>
                                    <td>30 Days</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Container>
            </section>
        </>
    )
}

export default Pricing
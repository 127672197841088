import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick';

export const Testomonials = () => {
    var settings = {
        dots: false,
        arrows:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000
    };
    return (
        <>
            <section className='light-bg testomonials'>
                <Container>
                    <div className="heading text-center pt-5">
                        <h3 className='m-0'>Testimonial</h3>
                        <img src="./images/line.svg" width='145' alt="" />
                    </div>
                    <Row className='mt-5 justify-content-center'>
                        <Col md={6} className='text-center'>
                            <img src="./images/christmas/testomonail.png" className='img-fluid br-15' alt="" />
                        </Col>
                        <Col md={5} className='my-auto'>
                            <Slider {...settings}>
                               <div>
                                    <Card className='text-center py-4 px-3 br-15 border-0 bg-transparent'>
                                        <img className='mx-auto' src="./images/quotes.svg" width={45} alt="" />
                                        <p className='mt-3'>Great customer service and timely deliveries. Very satisfied!</p>
                                        <h5>Pauline Bonella</h5>
                                    </Card>
                               </div>
                               <div>
                                    <Card className='text-center py-4 px-3 br-15 border-0 bg-transparent'>
                                        <img className='mx-auto' src="./images/quotes.svg" width={45} alt="" />
                                        <p className='mt-3'>Professional and trustworthy. They handle my shipments with care. Highly recommended.</p>
                                        <h5>Peter Pual</h5>
                                    </Card>
                               </div>
                               <div>
                                    <Card className='text-center py-4 px-3 br-15 border-0 bg-transparent'>
                                        <img className='mx-auto' src="./images/quotes.svg" width={45} alt="" />
                                        <p className='mt-3'>Fast and efficient shipping. I'll choose Kangaroo for all logistics needs.</p>
                                        <h5>Jasmine</h5>
                                    </Card>
                               </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

import React, { Component } from 'react';
import Navmenu from './NavMenu';
import { Footer } from './Footer';

const Layout=({children})=> {

    return (
      <div>
        <Navmenu />
          {children}
        <Footer/>
      </div>
    );
}


export default Layout;
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'
import { API_SERVER } from '../../Util'
import { JamboHeader } from '../Common/JamboHeader'

const Tracking = () => {
    const [trackingNumber, setTrackingNumber] = useState('')
    const [loader, setLoader] = useState(false)
    const [trackDetails, setTrackDetails] = useState({})

    const TrackData = async (e) => {
        e.preventDefault();
        setLoader(true)
        let request = {
            Token: "BDBDKSDIWEGFDIWEEFB239463213E87F",
            AwbNo: trackingNumber,
        }
        setLoader(true)
        const handleSendApi = await fetch(`${API_SERVER}/api/ContactUs/AwbTracking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        });
        const res = await handleSendApi.json();
        if (res.status === "SUCCESS") {
            setTrackDetails(res)
            setLoader(false)
        } else if (res.status === "ERROR") {
            setTrackDetails({})
            setLoader(false)
            toast.error(res.message)
        }
    }
    return (
        <>
            <Helmet>
                <title>Tracking | Kangaroo Express</title>
                <meta name="description" content="Get to know about the Kangaroo Express, a well-established company of over two decades and ensuring daily fulfilment of customer needs. Contact us today." />
            </Helmet>
            <JamboHeader pagename='Tracking' />
            <section className='tracking_section'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="tracking-bx">
                                <h4>Tracking No./AWB No.</h4>
                                <div
                                    className="track-input-field">
                                    <div
                                        style={{ width: '80%' }}
                                        className="fields">
                                        <input
                                            type="text"
                                            value={trackingNumber}
                                            className="form-control"
                                            id="txtTrackingNo"
                                            name="txtTrackingNo"
                                            placeholder="Tracking No."
                                            tabindex="1"
                                            autoComplete='off'
                                            onChange={(e) => setTrackingNumber(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    TrackData(e)
                                                }
                                            }}
                                        />
                                    </div>
                                    {
                                        loader ? <div className="menu-button destop-track">
                                            <div className="button-box">
                                                <button id="btnTrack" className="themebtn mt-4 mt-md-0 rounded">
                                                    Loading...
                                                </button>
                                            </div>
                                        </div>
                                            :
                                            <div className="menu-button destop-track">
                                                <div className="button-box">
                                                    <button onClick={(e) => TrackData(e)} id="btnTrack" className="themebtn mt-4 mt-md-0 rounded">Track Now
                                                        <div className="icon-box">
                                                            <i className='bx bx-chevron-right bx-fade-left' ></i>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            {
                                Object.keys(trackDetails).length !== 0 &&
                                <div>
                                    <div className="tracking-section">
                                        <div className="track-border">
                                            <div className="track-top">
                                                <ul className="mx-2">
                                                    <li><span className="bold-text">AWB No. : </span> {trackDetails?.awbNo}</li>
                                                    <li><span className="bold-text">Date : </span>{trackDetails?.bookingDate}</li>
                                                    <li><span className="bold-text">Tracking No. : </span>{trackDetails?.carrierAWBNo}</li>
                                                </ul>
                                                <span className="toggle-point"><i className="fa fa-chevron-down" aria-hidden="true"></i></span>
                                            </div>
                                            <div id="track-data" >
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="packet-status">
                                                            <h6 className="purple-text">Packet Status</h6>
                                                                <table className="table table-bordered table-responsive">
                                                                    <tbody style={{ textAlign: "left" }}>
                                                                        <tr>
                                                                            <th style={{ color: "#000", textAlign:"start" }}>Customer Name</th>
                                                                            <td style={{ textAlign: "start" }}>{trackDetails?.customerName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                            <th style={{ color: "#000", textAlign: "start" }}>Customer Address</th>
                                                                            <td style={{ textAlign: "start" }}>{trackDetails?.customerAddress}</td>
                                                                    </tr>
                                                                    <tr>
                                                                            <th style={{ color: "#000", textAlign: "start" }}>Carrier</th>
                                                                            <td style={{ textAlign: "start" }}>{trackDetails?.ecomWebsiteCarrier}</td>
                                                                    </tr>
                                                                    <tr>
                                                                            <th style={{ color: "#000", textAlign: "start" }}>Shipping Mode</th>
                                                                            <td style={{ textAlign: "start" }}>{trackDetails?.shippingMode}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="packet-history">
                                                            <h6 className="purple-text">Packet History</h6>
                                                            <div className="white-box table-responsive">
                                                                <ul className="tracking">
                                                                    {
                                                                        trackDetails?.trackingDetails?.map((item, index) => (
                                                                            <li key={index}>
                                                                                <div className="tracking-date">
                                                                                    <div className="tracking-body"><h5 className="tracking-title m-b-0">{item?.entryDate}</h5></div>
                                                                                    <div className="tracking-body"><h5 className="tracking-title m-b-0">{item?.time}</h5></div>
                                                                                </div>
                                                                                <div className="tracking-badge success">
                                                                                    {/*<FontAwesomeIcon*/}
                                                                                    {/*    icon={faCheck} />*/}
                                                                                </div>
                                                                                <div className="tracking-panel">
                                                                                    <div className="tracking-body">
                                                                                        <h4 className="tracking-title m-b-0">{item?.entryDetail}</h4>
                                                                                        <p>{item?.status}</p>
                                                                                        <p>{item?.location}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div >
                                    </div >

                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Tracking
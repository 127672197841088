import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Services = () => {
  return (
    <>
        <section className='light-bg'>
            <Container>
                <div className="heading text-center">
                      <h3 className='m-0'>How Our Service Works</h3>
                    <img src="./images/line.svg" width='145' alt="" />
                </div>
                  <Row className='mt-5'>
                      <Col className="mb-4 mb-md-0" md={6}>
                          <Row>
                      <Col className='d-flex justify-content-center align-items-baseline' xs={4}>
                        <img src="./images/delivery.svg" style={{width:'80%'}} alt="" />
                      </Col>
                      <Col xs={8}>
                        <h4 className='text-theme'>United States Mailbox Services:</h4>
                        <p>We offer a unique service catering to Caribbean residents. Here’s how it works...</p>
                        <Link className='d-flex align-items-center' to='/services'>Read More <i style={{fontSize:'18px'}} className='bx bx-chevron-right bx-tada' ></i></Link>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col className='d-flex justify-content-center align-items-baseline' xs={4}>
                        <img src="./images/shoping.svg" style={{width:'80%'}} alt="" />
                      </Col>
                      <Col xs={8}>
                        <h4 className='text-theme'>Shopping and Product Sourcing Services:</h4>
                        <p>For customers who prefer not to shop online, we offer comprehensive shopping and sourcing services...</p>
                        <Link className='d-flex align-items-center' to='/services'>Read More <i style={{fontSize:'18px'}} className='bx bx-chevron-right bx-tada' ></i></Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            </Container>
        </section>
    </>
  )
}

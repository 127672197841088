import Aboutus from "./components/About/Aboutus";
import Thankyou from "./components/Common/Thankyou";
import { Contactus } from "./components/Contact/Contactus";
import FAQs from "./components/FAQs/FAQs";
import Home from "./components/Home/Home";
import Pricing from "./components/Pricing/Pricing";
import Services from "./components/Services/Services";
import Signup from "./components/Signup/Signup";
import Tracking from "./components/Tracking/Tracking";


const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    index: true,
    element: <Contactus />,
    path:'/contactus'
  },
  {
    index: true,
    element: <Aboutus />,
    path:'/aboutus'
  },
  {
    index: true,
    element: <Services />,
    path:'/services'
  },
  {
    index: true,
    element: <Pricing />,
    path:'/pricing'
  },
  {
    index: true,
    element: <FAQs />,
    path:'/faqs'
  },
  {
    index: true,
    element: <Thankyou />,
    path:'/thankyou'
  },
  {
    index: true,
    element: <Signup />,
    path:'/signup'
  },
  {
    index: true,
    element: <Tracking />,
    path:'/tracking'
  }
];

export default AppRoutes;

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { JamboHeader } from '../Common/JamboHeader'
import MainHeading from '../Common/MainHeading'
import { CTA } from '../Home/CTA'
import { Helmet } from 'react-helmet'

const Services = () => {
    return (
        <>
            <Helmet>
                <title>U.S Mailbox Services | Shopping & Product Sourcing Services</title>
                <meta name="description" content="From U.S Mailbox Services Shopping & Product Sourcing Services, we ensure secure package handling to efficient product sourcing with professionalism and care." />
            </Helmet>
        <JamboHeader pagename='Services'/>
            <section>
                <Container>
                    <Row className='mt-5 mb-md-5'>
                        <Col className='mb-5 mb-md-0 order-2 order-md-1' md={6}>
                            <MainHeading heading='United States Mailbox Service:'/>
                            <p>We offer a unique service catering to Caribbean residents. Here’s how it works:</p>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <p><b className='text-dark'>Shop Online or Locally in Miami:</b> Residents can shop online or utilize our Kangaroo Express staff to shop locally in the Miami area for their favorite items at competitive prices.</p>
                            </div>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <p><b className='text-dark'>Physical Shipping Address in Miami:</b> Each customer receives a physical shipping address in Miami. This address is used for online orders or receiving packages from friends and family.</p>
                            </div>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <div>
                                    <p><b className='text-dark'>Receiving and Processing:</b>  All packages arrive at our modern facility where they are:</p>
                                    <ul>
                                        <li>Received</li>
                                        <li>Sorted</li>
                                        <li>Scanned</li>
                                        <li>Stored in our fully air-conditioned, temperature-controlled warehouse.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <p><b className='text-dark'>Shipping to Destination:</b> Shipments are scheduled and sent to various Caribbean destinations.</p>
                            </div>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <p><b className='text-dark'> Item Pickup:</b> Customers can pick up their items from our designated locations or request pickup services.</p>
                            </div>
                        </Col>
                        <Col md={6} className='order-1 order-md-2'>
                            <img src="./images/delivery.svg" style={{ width: '80%' }} alt="" />
                        </Col>
                    </Row>
                    <Row className='mt-5 justify-content-between'>
                        <Col className='mb-5 mb-md-0' md={5}>
                            <img src="./images/people.svg" alt="" />
                        </Col>
                        <Col md={6}>
                            <MainHeading heading='Shopping and Product Sourcing Services:'/>
                            <p>For customers who prefer not to shop online, we offer comprehensive shopping and sourcing services:</p>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <p><b className='text-dark'>In-Store Purchases:</b> We accept orders and shop in-store at various US stores on behalf of our customers.</p>
                            </div>
                            <div className="d-flex mb-2">
                                <span><img src="./images/arrow_icon.png" className='arrow_icon' alt="" /></span>
                                <p><b className='text-dark'>Hard-to-Find Items:</b> We specialize in finding difficult-to-source items upon request.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <CTA bg={true}/>
        </>
    )
}

export default Services
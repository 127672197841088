import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const JamboHeader = ({pagename}) => {
  return (
        <>
            <div className="jmbo">
                <Container>
                    <Row>
                        <Col xs={8} className='my-auto'>
                            <h1 className='text-theme'>{pagename}</h1>
                            {/*<p><Link to='/'>Home</Link> / {pagename}</p>*/}
                        </Col>
                        <Col xs={4} className='text-end'>
                            <img src="./images/kangaroo2.png" alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
  )
}

import React, { useState } from 'react'

const MainHeading = ({heading, center=false}) => {
  return (
    <>
        <div className={`heading ${center ? 'text-center' : ''}`}>
            <h3 className='m-0'>{heading}</h3>
            <img src="./images/line.svg" width='145' alt="" />
        </div>
    </>
  )
}

export default MainHeading
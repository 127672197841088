import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Thankyou = () => {

    const [urlText, setUrlText] = useState('')
    
    useEffect(() => {
        const getQueryParams = () => {
            const currentUrl = window.location.href;
            const thankyouIndex = currentUrl.indexOf('thankyou?');
            if (thankyouIndex !== -1) {
                const afterThankYou = currentUrl.substring(thankyouIndex + 'thankyou?'.length);
                setUrlText(afterThankYou)
                console.log(afterThankYou); 
            } else {
                console.log('URL does not contain "thankyou?"');
            }
        };
        getQueryParams();
    }, []);
  return (
    <>
        <section className='thankyou'>
            <Container>
               <Row className='justify-content-center'>
                 <Col md={8} className='text-center'>
                    <div className="ty_image position-relative">
                        <img src="./images/thankyou.png" alt="" />
                    </div>
                          <h4>Thank you for {urlText} with us</h4>
                    <p>We will contact you as soon as possible. We are committed to providing you with an unforgettable experience.</p>
                    <Link to='/' className='themebtn mt-4 mt-md-0 rounded d-inline-flex'>Back To Home
                            <div className="icon-box">
                            <i className='bx bx-chevron-right bx-fade-left' ></i>
                    </div>
                    </Link>
                 </Col>
               </Row>
            </Container>
        </section>
    </>
  )
}

export default Thankyou
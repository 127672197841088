import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './custom.css';
import './Assets/responsive.css'
import Layout from './components/Layout';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'boxicons/css/boxicons.css';
import 'boxicons/dist/boxicons.js';
import Loader from './components/Common/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return null;
}

const App = () => {
  // useEffect(() => {
  //     AOS.init();
  // }, [])
  return (
    <>
          <ScrollToTop />
          <ToastContainer position="top-right" theme="colored" newestOnTop={true} />
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route key={index} {...rest} element={element} />;
            })}
          </Routes>
        </Layout>
    </>
  );
}

export default App;

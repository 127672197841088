import React from 'react'
import { JamboHeader } from '../Common/JamboHeader'
import { Accordion, Container } from 'react-bootstrap'
import MainHeading from '../Common/MainHeading'
import { Helmet } from 'react-helmet'

const FAQs = () => {
    return (
        <>
            <Helmet>
                <title>Frequently Ask Questions (FAQs) | Kangaroo Express</title>
                <meta name="description" content="Get the answers to most FAQs regarding the U.S. mailbox, and product sourcing services to help you understand our offerings and processes.  Get to know more." />
            </Helmet>
            <JamboHeader pagename='FAQs' />

            <section>
                <Container >
                    <MainHeading heading="Frequently ask questions" />
                    <Accordion className='mt-4' defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Can I shop at US online stores and have the goods delivered to my home?</Accordion.Header>
                            <Accordion.Body>
                                Yes, shop top brands and great deals available only from USA retailers like Amazon, Etsy, etc. Simply enter our Miami address at checkout, and your purchases will be shipped to our USA facility.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How long does it take to ship items?</Accordion.Header>
                            <Accordion.Body>
                                We can deliver your shipment the next working day once we receive it at our USA facility. Furthermore, the number of days depends on the service you have opted for.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Can I cancel the order? </Accordion.Header>
                            <Accordion.Body>
                                Yes, you can cancel your order anytime. However, it is not possible to cancel shipments once they reach our USA facility.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Can I return the shipments I have received?</Accordion.Header>
                            <Accordion.Body>
                            No, you cannot return the shipments once it reaches our USA Facility.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>
            </section>
        </>
    )
}

export default FAQs
import React from 'react'
import { JamboHeader } from '../Common/JamboHeader'
import { Col, Container, Row } from 'react-bootstrap'
import MainHeading from '../Common/MainHeading'
import { CTA } from '../Home/CTA'
import { Helmet } from 'react-helmet'

const Aboutus = () => {
  return (
    <>
        <Helmet>
                <title>About us | Kangaroo Express</title>
                <meta name="description" content="Get to know about the Kangaroo Express, a well-established company of over two decades and ensuring daily fulfilment of customer needs. Contact us today." />
            </Helmet>
        <JamboHeader pagename='About Us'/>
        <section className='aboutus'>
            <Container>
                <Row>
                    <Col md={6} className='my-auto order-2 order-md-1'>
                    <MainHeading heading="Kangaroo Express"/>
                    <ul className='ps-3'>
                        <li>Kangaroo Express was established in 2001 in Antigua.</li>
                        <li>With over two decades of successful operation, we have built a strong foundation.</li>
                        <li>Our customer-centered approach has been pivotal in shaping our company’s success.</li>
                        <li>Leveraging our extensive experience, we consistently deliver premium services to our clients.</li>
                        <li>Equipped with knowledge and resources, we are prepared to advance our services to meet future demands.</li>
                        <li>Our well-trained staff excel in all aspects of our business, ensuring daily fulfillment of customer needs.</li>
                        <li>Kangaroo Express is expanding rapidly; we plan to be fully operational in 5 Caribbean islands by the end of 2024 and 10 by the end of 2025.</li>
                    </ul>
                    </Col>
                    <Col className='d-flex justify-content-center align-items-center order-1 order-md-2'>
                        <img src="./images/man2.svg" className='w-75 mx-auto' alt="" />
                    </Col>
                </Row>
            </Container>
        </section>

        <Container>
            <section>
                <Row>
                    <Col md={6}>
                        <div className="light-bg p-3 br-15 box-shadow">
                            <div className="text-center mb-4">
                                <img src="./images/Technology.svg" className='w-75 mx-auto' alt="" />
                            </div>
                            <MainHeading heading="Technology" />
                            <p>Kangaroo Express leverages cutting-edge technology to streamline its operations and enhance efficiency. Our extensive use of scanners to document and track every shipment passing through our network has enabled us to establish a highly effective Trace and Track system. Additionally, the company has achieved complete computerization across its network, ensuring seamless integration and operational excellence.</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="light-bg p-3 br-15 box-shadow">
                            <div className="text-center mb-4">
                                <img src="./images/people.png" className='w-75 mx-auto' alt="" />
                            </div>
                            <MainHeading heading="People" />
                            <p>Kangaroo Express is led by a team of young, experienced managers across all locations who oversee daily operations and provide strategic guidance to the company. Our middle management comprises dedicated professionals who ensure seamless operations, maintaining high standards of service quality. Furthermore, a robust workforce, both direct and indirect, plays a crucial role in executing operations smoothly at the grassroots level.</p>
                        </div>
                    </Col>
                </Row>
            </section>
        </Container>

        <CTA/>
    </>
  )
}

export default Aboutus
const config = {
    secret_key: process.env.REACT_APP_SECRET_KEY,
}
export default config

let baseUrl;
if (window.location.hostname.indexOf("react.") >= 0) {
    baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
} else {
    baseUrl = `${window.location.protocol}//${window.location.host}`;
}
export const API_SERVER = baseUrl;

export const initializeFilterData = {
    "SearchText": "",
    "StartValue": "2",
    "SearchFieldValue": "",
    "PageSize": "10",
    "AdvanceFilter": []
}
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
        <>
            <footer className='primary-bg'>
              <Container fluid>
                    <Row>
                        <Col className='mb-3 mb-md-0' md={3}>
                          <img src="./images/christmas/logo-white.png" width={200} alt="" />
                            <p className='mt-3'>Welcome to Kangaroo Expresss! Since 2008, we’ve provided an easy, affordable, and reliable shopping experience from the comfort of your home.</p>
                          <div className="d-flex socialLinks">
                              <a href=""><i className='bx bxl-instagram-alt'></i></a>
                              <a href=""><i className='bx bxl-facebook' ></i></a>
                              <a href=""><i className='bx bxl-pinterest' ></i></a>
                          </div>
                        </Col>                      
                        
                        <Col className='mb-3 mb-md-0' md={4}>
                          <h5 className='text-white mb-0'>Kangaroo Expresss Head Office</h5>
                            <img src="./images/line.svg" width={75} alt="" />
                          {/*<p><i className='bx me-1 mt-1 bxs-map bx-tada' ></i> 3062 W Missionwood Circle, Miramar, Florida, 33126</p>*/}
                          <p><i className='bx me-1 mt-1 bxs-map bx-tada' ></i> 3600 NW 114 Ave, Suite A, Doral, Florida, 33178</p>
                          <p><i className='bx me-1 mt-1 bxs-envelope bx-tada' ></i> <a href="mailto:Info@kangarooexpress.shop">Info@kangarooexpress.shop</a></p>
                            {/*<p><i className='bx me-1 mt-1 bxs-phone-call bx-tada' ></i><a href="tel:869-665-4600">869-665-4600</a></p>*/}
                            
                      </Col>
                      <Col className='mb-3 mb-md-0' md={3}>
                          <h5 className='text-white mb-0'>Kangaroo Express St Kitts</h5>
                          <img src="./images/line.svg" width={75} alt="" />
                          <p><i className='bx me-1 mt-1 bxs-map bx-tada' ></i> Rosemary Lane Basseterre St Kitts</p>
                          <p><i className='bx me-1 mt-1 bxs-envelope bx-tada' ></i> <a href="mailto:Skb@kangarooexpress.shop">Skb@kangarooexpress.shop</a></p>
                          {/*<p><i className='bx me-1 mt-1 bxs-phone-call bx-tada' ></i><a href="tel:869-665-4600">869-665-4600</a></p>*/}
                          <p><i className='bx me-1 mt-1 bxs-phone-call bx-tada' ></i><a href="tel:+1 1869 660 6022">+1 1869 660 6022</a></p>
                          <p><i className='bx me-1 mt-1 bxs-phone-call bx-tada' ></i><a href="tel:+1 1869 466 6022">+1 1869 466 6022</a></p>
                          {/*<div className='mt-3 d-flex gap-2 hq'>*/}
                          {/*    <i class='bx bx-mail-send' ></i>*/}
                          {/*    <p><a href="mailto:Skb@kangarooexpress.shop">Skb@kangarooexpress.shop</a></p>*/}
                          {/*</div>*/}
                          {/*<div className='mt-3 d-flex gap-2 hq'>*/}
                          {/*    <i class='bx bx-phone-call' ></i>*/}
                          {/*    <p><a href="tell:869-665-4600">869-665-4600</a></p>*/}
                          {/*</div>*/}
                      </Col>
                      <Col className='mb-3 mb-md-0' md={2}>
                          <h5 className='text-white mb-0'>Coming soon to</h5>
                          <img src="./images/line.svg" width={75} alt="" />
                          <ul className="ps-3">
                              <li>Dominican Republic</li>
                              <li>St. Maartin</li>
                              <li>Guyana GY</li>
                              <li>St. Lucia</li>
                          </ul>
                      </Col>
                    </Row>
                </Container>
            </footer>
            <div className="footer-bottom py-2" style={{backgroundColor:'#093625'}}>
                <Container>
                    <div className="d-block d-md-flex justify-content-between align-items-center">
                        <p className='mb-0'><small className='text-white-50'>© 2024 Kangaroo Express all rights reserved</small></p>
                        <small className='text-white-50'>Designed by:  <a href="https://www.sagarinfotech.com/">SIPL</a></small>
                    </div>
                </Container>
            </div>
        </>
  )
}

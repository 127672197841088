import React from 'react'
import { Modal } from 'react-bootstrap'

const Popupads = () => {

    const [modalShow, setModalShow] = React.useState(true);

    return (
        <>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className='p-0 border-0' closeButton>
                </Modal.Header>
                <Modal.Body>
                    <a href="../signup">
                        <img src="./images/post.png" className='img-fluid' alt="Kangaroo Express" />
                    </a>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Popupads
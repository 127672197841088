import React from 'react';
import { Button, Card, Col, Container, Form, FormControl, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { Testomonials } from './Testomonials';
import { CTA } from './CTA';
import { Services } from './Services';
import Popupads from './Popupads';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';

const Home = () => {
    const signupButton = {
        width: 'fit-content',       
    };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
        {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <>
    <Helmet>
      <title>Shop and Ship Service | Kangaroo Express</title>
      <meta name="description" content="Kangaroo Express is a reliable delivery service provider tailored to meet your needs. With years of experience and experts, always ready to deliver your goods." />
    </Helmet>
      <div className="hero_section position-relative">
        <Container fluid>
          <Row className='justify-content-between'>
            <Col lg={6}>
              <img src="./images/christmas/kangaroo.webp" className='img-fluid' alt="" />
            </Col>
            <Col lg={6} className='mt-3 mt-md-5'>
              <h3 className='mt-4 text-theme'>You Shop It...We Hop It</h3>
              <h1>Catch That Kangaroo</h1>
                          <p>Ship anything from a small parcel to a large freight shipment. View real time tracking information online for every shipment.</p>
                          <LinkContainer style={signupButton} className='themebtn rounded px-3 py-2 text-white' to="/signup">
                              <Link to='/signup' className='me-3'>Signup <div className="icon-box">
                                  <i class='bx bx-log-in-circle bx-tada' style={{ fontSize: '20px' }} ></i>
                              </div></Link>
                          </LinkContainer>
              {/* <Card className='p-3 br-15'>
                <Form.Group controlId="formBasicEmail">
                  <div className="d-flex justify-content-between mb-3">
                    <Form.Label><b>Track By :-</b></Form.Label>
                    <div>
                      <Form.Check
                        className='me-2'
                        inline
                        label="AWB No."
                        name="tracking"
                        type='radio'
                        id='awb'
                      />
                      <Form.Check
                        className='me-2'
                        inline
                        label="Tracking No."
                        name="tracking"
                        type='radio'
                        id='teackingno'
                      />
                      <Form.Check
                        className='me-2'
                        inline
                        label="Ref No."
                        name="tracking"
                        type='radio'
                        id='refno'
                      />
                    </div>
                  </div>
                  <Form.Control className='bg-light' type="email" placeholder="Enter Your AWB No." />
                </Form.Group>

                <Button className='mt-3 primary-bg border-0'>Track Now</Button>

                <ul className='mt-3'>
                  <li>Please Enter AWB Number In Textarea.</li>
                  <li>Enter Your Parcel Tracking Number In The Box Below And Click 'Track Now'.</li>
                  <li>Please Press Enter For New Line If You Want To Search Multiple AWB Numbers.</li>
                </ul>
              </Card> */}
            </Col>
          </Row>
              </Container>
              <div className='snowhorse'>
                  <img src="./images/christmas/snowhorse.png" className='img-fluid' alt="" />
              </div>
      </div>

      <section className='aboutus position-relative'>
        <Container>
          <Row>
            <Col md={6} className='my-auto order-2 order-md-1'>
              <div className="heading">
                <h3 className='m-0'>About Us</h3>
                <img src="./images/line.svg" width='145' alt="" />
              </div>
              <ul className='ps-3'>
                <li>Kangaroo Express was established in 2001 in Antigua.</li>
                <li>With over two decades of successful operation, we have built a strong foundation.</li>
                <li>Our customer-centered approach has been pivotal in shaping our company’s success.</li>
                <li>Leveraging our extensive experience, we consistently deliver premium services to our clients.</li>
                <li>Equipped with knowledge and resources, we are prepared to advance our services to meet future demands.</li>
                <li>Our well-trained staff excel in all aspects of our business, ensuring daily fulfillment of customer needs.</li>
                <li>Kangaroo Express is expanding rapidly; we plan to be fully operational in 5 Caribbean islands by the end of 2024 and 10 by the end of 2025.</li>
              </ul>
                <Link to='/aboutus' className='themebtn mt-4 mt-md-0 rounded d-inline-flex'>Read More
                  <div className="icon-box">
                  <i className='bx bx-chevron-right bx-fade-left' ></i> 
                  </div>
                </Link>
            </Col>
            <Col className='d-flex justify-content-center align-items-center order-1 order-md-2'>
              {/*<img src="./images/man2.svg" className='w-75 mx-auto' alt="" />*/}
              <img src="./images/christmas/santa.png" className='w-100 mx-auto' alt="" />
            </Col>
          </Row>
              </Container>
              {/*<div className='snowman'>*/}
              {/*    <img src="./images/christmas/snowman.png" className='img-fluid' alt="" />*/}
              {/*</div>*/}
      </section>

      <Services />

      <Container className='pt-5'>
        <section className='shopon bg-light br-15 py-5'>
          <div className="heading text-center">
            <h3 className='m-0'>Shop On </h3>
            <img src="./images/line.svg" width='145' alt="" />
          </div>
          <Row className='mt-5'>
            <Slider {...settings}>
              <div className='p-3 rounded mx-3 bg-light'>
                <img src="./images/shop/aliexpress.jpg" alt="" />
              </div>
              <div className='p-3 rounded mx-3 bg-light'>
                <img src="./images/shop/amazon2.jpg" alt="" />
              </div>
              <div className='p-3 rounded mx-3 bg-light'>
                <img src="./images/shop/bestbuy1.jpg" alt="" />
              </div>
              <div className='p-3 rounded mx-3 bg-light'>
                <img src="./images/shop/ebay.jpg" alt="" />
              </div>
              <div className='p-3 rounded mx-3 bg-light'>
                <img src="./images/shop/oldnavy2.jpg" alt="" />
              </div>
              <div className='p-3 rounded mx-3 bg-light'>
                <img src="./images/shop/toyrus1.jpg" alt="" />
              </div>
            </Slider>
          </Row>
        </section>
      </Container>

    <section className='howwework'>
      <Container>
        <Row className='position-relative justify-content-between'>
          <Col md={4} className='text-center'>
            <img src="./images/christmas/whoa.png" className='img-fluid whoa' alt="" /></Col>
          <Col md={7} className='d-flex flex-column-reverse mb-3 mb-md-5'>
            <div className="heading mb-3">
              <h3 className='m-0'>How It Works </h3>
              <img src="./images/line.svg" width='145' alt="" />
              <p>Shop With Confidence That You’ll Always Receive Your Goods With These 3 Simple Steps</p>
            </div>
          </Col>
        </Row>
        <Row className='mt-5 steps'>
          <Col className='text-center mb-4 mb-md-0' md={4}>
            <img src="./images/online-shop.svg" alt="" />
            <h5 className='mt-4'>Shop Online</h5>
            <p>Avoid lines and timing consuming waits, at customs, usually associated with international shipping.</p>
          </Col>
          <Col className='text-center mb-4 mb-md-0' md={4}>
            <img src="./images/map.svg" alt="" />
            <h5 className='mt-4'>Use Your Kangaroo Express Address</h5>
            <p>Just enter your Kangaroo Express Address at Checkout and we will take care of the rest.</p>
          </Col>
          <Col className='text-center mb-4 mb-md-0' md={4}>
            <img src="./images/delivery-man.svg" alt="" />
            <h5 className='mt-4'>We deliver</h5>
            <p>Kangaroo Express will then deliver your package to your home or office.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <Testomonials/>
    <CTA/>

    <Popupads/>
    </>
  )
}

export default Home;


